import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { GetRequest } from '@qb/httpRequest/Request';
import { getRestApiPrefixForTenant } from '@qb/httpRequest/utils';
import featureFlips from '@/shared/models/FeatureFlipConstants';
import { TenantAlias } from '@/shared/tenants-alias-map';
import { FeatureFlipApiResponse } from '../../types/apiResponseTypes';
import { User } from '../../types/types';
import { RootState } from '../rootReducer';

export type MainState = {
  user: User | null;
  hasFetchedUser: boolean;
  userProfileImages: Record<number, string>;
  companyLogoImages: Record<number, string>;
  featureFlips: Record<string, number>;
};

export const fetchFeatureFlips = createAsyncThunk<
  FeatureFlipApiResponse['featureFlips'],
  { tenantAlias: TenantAlias }
>(`/featureFlip`, async ({ tenantAlias }) => {
  return new Promise((resolve, reject) => {
    GetRequest(
      `${getRestApiPrefixForTenant(tenantAlias)}/featureFlip`,
      (data: FeatureFlipApiResponse) => {
        return resolve(data.featureFlips);
      },
      (err: Error) => {
        console.error(err);
        reject(err);
      },
    );
  });
});

export const getInitialStore = (): MainState => ({
  user: null,
  hasFetchedUser: false,
  userProfileImages: {},
  companyLogoImages: {},
  featureFlips: featureFlips.initialFeatureFlips(),
});

const main = createSlice({
  name: 'main',
  initialState: getInitialStore(),
  reducers: {
    setUser: (store, { payload }) => ({
      ...store,
      user: payload,
      hasFetchedUser: true,
    }),
    setUserAfterSignup: (store, { payload }) => ({
      ...store,
      user: payload,
      hasFetchedUser: false,
    }),
    addUserProfileImage: (store, { payload }) => {
      store.userProfileImages[payload.id] = payload.url;
    },
    addCompanyLogoImage: (store, { payload }) => {
      store.companyLogoImages[payload.id] = payload.url;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchFeatureFlips.fulfilled, (state, { payload }) => {
      if (payload.length === 0) {
        state.featureFlips = featureFlips.initialFeatureFlips();
      }

      payload.forEach((featureFlip) => {
        state.featureFlips[featureFlip.name] = featureFlip.value;
      });
    });
  },
});

export const { setUser, addUserProfileImage, addCompanyLogoImage } =
  main.actions;

export const isSupplierSelector = ({ main }: RootState): boolean =>
  Boolean(main?.user?.company?.isSupplier) ?? false;

export const isBuyerSelector = ({ main }: RootState): boolean =>
  Boolean(main?.user?.company?.isBuyer) ?? false;

export const isManufacturerSelector = ({ main }: RootState): boolean =>
  Boolean(main?.user?.company?.isManufacturer) ?? false;

export const isAuthenticatedSelector = ({ main }: RootState): boolean =>
  Boolean(main?.user?.id) ?? false;

export const userIdSelector = ({ main }: RootState): number | undefined =>
  main?.user?.id;

export const userSelector = ({ main }: RootState): MainState['user'] =>
  main?.user;

export const isAdminSelector = ({ main }: RootState): boolean =>
  Boolean(main?.user?.admin) ?? false;

export default main.reducer;
