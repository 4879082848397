'use client';

import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/navigation';
import { Box } from '@mui/material';
import { EventEmitter } from '@qb/frontend/utils/EventEmitter';
import { SocketGetRequest } from '@qb/httpRequest/SocketRequest';
import { BAD_REQUEST_EVENT, FORBIDDEN_ERROR_CODE, NOT_FOUND_ERROR_CODE, UNAUTHORIZED_ERROR_CODE } from '@qb/httpRequest/constants';
import { getWebApiPrefixForTenant } from '@qb/httpRequest/utils';
import CustomModalWrapper from '@/src/components/Common/CustomModal/CustomModalWrapper';
import { RightDrawerWrapper } from '@/src/components/Common/RightDrawer/RightDrawerWrapper';
import ErrorBoundary from '@/src/components/Components/ErrorBoundary';
import { SidebarDesktopContainer } from '@/src/components/Layouts/AppLayout/Sidebar/SidebarDesktopContainer';
import { useWarnOnContextWidthLargerThanViewport } from '@/src/components/Layouts/AppLayout/hooks/useWarnOnContextWidthLargerThanViewport';
import { NAVBAR_HEIGHT, NAVBAR_HEIGHT_MOBILE } from '@/src/components/Layouts/AppLayout/navbarConstants';
import { useTenantAlias } from '@/src/hooks/useTenantAlias';
import { isAuthenticatedSelector } from '@/src/store/reducers/main';
import { Navbar } from './Navbar/Navbar';
const NotFoundPage = dynamic(() => import('@/src/components/Error/NotFoundPage').then(mod => mod.NotFoundPage));
type AppLayoutProps = {
  children: React.ReactNode;
  backgroundColor?: string;
  hasAuthenticatedLayoutCookie: boolean;
};
export const AppLayout = ({
  children,
  backgroundColor,
  hasAuthenticatedLayoutCookie
}: AppLayoutProps) => {
  const router = useRouter();
  const isAuthenticatedUser = useSelector(isAuthenticatedSelector);
  const [overrideContent, setOverrideContent] = useState<React.ReactNode>(null);
  const tenantAlias = useTenantAlias();
  useWarnOnContextWidthLargerThanViewport();
  useEffect(() => {
    const badRequestEventHandler = (status: number) => {
      if (status === FORBIDDEN_ERROR_CODE || status === NOT_FOUND_ERROR_CODE) {
        setOverrideContent(<NotFoundPage />);
      }
      if (status === UNAUTHORIZED_ERROR_CODE) {
        router.replace('/login');
      }
    };
    EventEmitter.subscribe(BAD_REQUEST_EVENT, badRequestEventHandler);
    return () => {
      EventEmitter.unsubscribe(BAD_REQUEST_EVENT, badRequestEventHandler);
    };
  }, [router]);
  useEffect(() => {
    if (isAuthenticatedUser) {
      SocketGetRequest(`${getWebApiPrefixForTenant(tenantAlias)}/subscribeToCompanySocket`);
    }
  }, [isAuthenticatedUser, tenantAlias]);
  const isSidebarVisible = hasAuthenticatedLayoutCookie || isAuthenticatedUser;
  return <>
      <Navbar data-sentry-element="Navbar" data-sentry-source-file="AppLayout.tsx" />
      <Box display="flex" height={{
      xs: `calc(100vh - ${NAVBAR_HEIGHT_MOBILE}px)`,
      md: `calc(100vh - ${NAVBAR_HEIGHT}px)`
    }} data-sentry-element="Box" data-sentry-source-file="AppLayout.tsx">
        {isSidebarVisible && <SidebarDesktopContainer />}
        <Box flexGrow={1} sx={{
        overflow: 'auto'
      }} data-cy="AppLayoutInnerContainer" data-sentry-element="Box" data-sentry-source-file="AppLayout.tsx">
          <ErrorBoundary data-sentry-element="ErrorBoundary" data-sentry-source-file="AppLayout.tsx">
            <Box data-cy="AppLayoutMain" component="main" sx={{
            height: '100%',
            width: '100%',
            backgroundColor: backgroundColor ?? 'background.default'
          }} data-sentry-element="Box" data-sentry-source-file="AppLayout.tsx">
              {overrideContent ? overrideContent : children}
            </Box>
          </ErrorBoundary>
        </Box>
      </Box>
      <RightDrawerWrapper data-sentry-element="RightDrawerWrapper" data-sentry-source-file="AppLayout.tsx" />
      <CustomModalWrapper router={router} data-sentry-element="CustomModalWrapper" data-sentry-source-file="AppLayout.tsx" />
    </>;
};