import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  SocketGetRequest,
  SocketGetRequestWithData,
} from '@qb/httpRequest/SocketRequest';
import { getWebApiPrefixForTenant } from '@qb/httpRequest/utils';
import { TenantAlias } from '@/shared/tenants-alias-map';
import {
  ConversationDisplayDataResponse,
  ConversationListDataResponse,
  ConversationSearchResponse,
} from '@/shared/types/controllers/ConversationControllerTypes';

export const SEARCH_TEXT_MIN_LENGTH = 3;

const fetchConversationData = createAsyncThunk<
  {
    conversation: ConversationDisplayDataResponse['conversation'];
    setAsSelected: boolean;
  },
  { conversationID: number; setAsSelected: boolean; tenantAlias: TenantAlias }
>(
  `/conversation/:id/displayData`,
  async ({ conversationID, setAsSelected, tenantAlias }) => {
    return new Promise((resolve, reject) => {
      if (conversationID) {
        SocketGetRequest(
          `${getWebApiPrefixForTenant(
            tenantAlias,
          )}/conversation/${conversationID}/displayData`,
          (data: ConversationDisplayDataResponse) => {
            return resolve({ ...data, setAsSelected });
          },
          (err: Error) => {
            console.error(err);
            reject(err);
          },
        );
      } else {
        return reject();
      }
    });
  },
);

const fetchConversationListData = createAsyncThunk<
  ConversationListDataResponse,
  { page: number; tenantAlias: TenantAlias }
>(`/conversation/listData`, async ({ page, tenantAlias }) => {
  return new Promise((resolve, reject) => {
    SocketGetRequestWithData(
      `${getWebApiPrefixForTenant(tenantAlias)}/conversation/listData`,
      { page: page },
      resolve,
      reject,
    );
  });
});

const fetchConversationsBySearch = createAsyncThunk<
  ConversationSearchResponse,
  { searchTerm: string; tenantAlias: TenantAlias }
>(`/conversation/search`, async ({ searchTerm, tenantAlias }) => {
  return new Promise((resolve, reject) => {
    if (searchTerm.trim().length < SEARCH_TEXT_MIN_LENGTH) {
      resolve({ searchConversationResults: [] });
      return;
    }
    SocketGetRequestWithData(
      `${getWebApiPrefixForTenant(tenantAlias)}/conversation/search`,
      { searchTerm: searchTerm },
      resolve,
      reject,
    );
  });
});

export {
  fetchConversationData,
  fetchConversationListData,
  fetchConversationsBySearch,
};
